// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LulNavbarNavCategoryListingFragment = {
  __typename?: 'NavCategoryListing';
  name: string;
  goal?: Types.GoalName | null;
  categories: Array<{
    __typename?: 'Category';
    id: string;
    name: string;
    isFeatured: boolean;
    slug: string;
    goal: Types.GoalName;
    shouldShowSubCategories?: boolean | null;
    department: { __typename?: 'Department'; id: string; name: string };
    subCategoryList?: Array<{
      __typename?: 'SubCategory';
      id: string;
      name: string;
      slug: string;
    }> | null;
  }>;
};

export type LulNavbarUserQueryVariables = Types.Exact<{ [key: string]: never }>;

export type LulNavbarUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    pictureLink?: string | null;
    applicant?: {
      __typename?: 'Applicant';
      id: string;
      lelandPlus?: { __typename?: 'LelandPlus'; active: boolean } | null;
    } | null;
    coach?: { __typename?: 'Coach'; id: string } | null;
  } | null;
};

export const LulNavbarNavCategoryListingFragmentDoc = gql`
  fragment LulNavbar_NavCategoryListing on NavCategoryListing {
    name
    goal
    categories {
      id
      name
      isFeatured
      slug
      goal
      department {
        id
        name
      }
      shouldShowSubCategories
      subCategoryList {
        id
        name
        slug
      }
    }
  }
`;
export const LulNavbarUserDocument = gql`
  query LulNavbar_User {
    user {
      id
      firstName
      lastName
      pictureLink
      applicant {
        id
        lelandPlus {
          active
        }
      }
      coach {
        id
      }
    }
  }
`;

/**
 * __useLulNavbarUserQuery__
 *
 * To run a query within a React component, call `useLulNavbarUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useLulNavbarUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLulNavbarUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useLulNavbarUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LulNavbarUserQuery,
    LulNavbarUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LulNavbarUserQuery, LulNavbarUserQueryVariables>(
    LulNavbarUserDocument,
    options,
  );
}
export function useLulNavbarUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LulNavbarUserQuery,
    LulNavbarUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LulNavbarUserQuery, LulNavbarUserQueryVariables>(
    LulNavbarUserDocument,
    options,
  );
}
export function useLulNavbarUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    LulNavbarUserQuery,
    LulNavbarUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LulNavbarUserQuery,
    LulNavbarUserQueryVariables
  >(LulNavbarUserDocument, options);
}
export type LulNavbarUserQueryHookResult = ReturnType<
  typeof useLulNavbarUserQuery
>;
export type LulNavbarUserLazyQueryHookResult = ReturnType<
  typeof useLulNavbarUserLazyQuery
>;
export type LulNavbarUserSuspenseQueryHookResult = ReturnType<
  typeof useLulNavbarUserSuspenseQuery
>;
export type LulNavbarUserQueryResult = Apollo.QueryResult<
  LulNavbarUserQuery,
  LulNavbarUserQueryVariables
>;

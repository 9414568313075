// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import { LulNavbarNavCategoryListingFragmentDoc } from '../../__generated-gql-types__/LulNavbar.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LayoutNavCategoryListingFragment = {
  __typename?: 'NavCategoryListing';
  name: string;
  goal?: Types.GoalName | null;
  categories: Array<{
    __typename?: 'Category';
    id: string;
    name: string;
    isFeatured: boolean;
    slug: string;
    goal: Types.GoalName;
    shouldShowSubCategories?: boolean | null;
    department: { __typename?: 'Department'; id: string; name: string };
    subCategoryList?: Array<{
      __typename?: 'SubCategory';
      id: string;
      name: string;
      slug: string;
    }> | null;
  }>;
};

export type LayoutHeaderDataQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type LayoutHeaderDataQuery = {
  __typename?: 'Query';
  navCategories: Array<{
    __typename?: 'NavCategoryListing';
    name: string;
    goal?: Types.GoalName | null;
    categories: Array<{
      __typename?: 'Category';
      id: string;
      name: string;
      isFeatured: boolean;
      slug: string;
      goal: Types.GoalName;
      shouldShowSubCategories?: boolean | null;
      department: { __typename?: 'Department'; id: string; name: string };
      subCategoryList?: Array<{
        __typename?: 'SubCategory';
        id: string;
        name: string;
        slug: string;
      }> | null;
    }>;
  }>;
};

export const LayoutNavCategoryListingFragmentDoc = gql`
  fragment Layout_NavCategoryListing on NavCategoryListing {
    ...LulNavbar_NavCategoryListing
  }
  ${LulNavbarNavCategoryListingFragmentDoc}
`;
export const LayoutHeaderDataDocument = gql`
  query Layout_HeaderData {
    navCategories {
      ...Layout_NavCategoryListing
    }
  }
  ${LayoutNavCategoryListingFragmentDoc}
`;

/**
 * __useLayoutHeaderDataQuery__
 *
 * To run a query within a React component, call `useLayoutHeaderDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLayoutHeaderDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLayoutHeaderDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useLayoutHeaderDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LayoutHeaderDataQuery,
    LayoutHeaderDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LayoutHeaderDataQuery, LayoutHeaderDataQueryVariables>(
    LayoutHeaderDataDocument,
    options,
  );
}
export function useLayoutHeaderDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LayoutHeaderDataQuery,
    LayoutHeaderDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LayoutHeaderDataQuery,
    LayoutHeaderDataQueryVariables
  >(LayoutHeaderDataDocument, options);
}
export function useLayoutHeaderDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    LayoutHeaderDataQuery,
    LayoutHeaderDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LayoutHeaderDataQuery,
    LayoutHeaderDataQueryVariables
  >(LayoutHeaderDataDocument, options);
}
export type LayoutHeaderDataQueryHookResult = ReturnType<
  typeof useLayoutHeaderDataQuery
>;
export type LayoutHeaderDataLazyQueryHookResult = ReturnType<
  typeof useLayoutHeaderDataLazyQuery
>;
export type LayoutHeaderDataSuspenseQueryHookResult = ReturnType<
  typeof useLayoutHeaderDataSuspenseQuery
>;
export type LayoutHeaderDataQueryResult = Apollo.QueryResult<
  LayoutHeaderDataQuery,
  LayoutHeaderDataQueryVariables
>;

import { isEmpty, isNil, omitBy } from 'lodash-es';
import type { UrlObject } from 'url';

export const getUrlString = (
  url: string,
  queryParams: UrlObject['query'],
  useHash?: boolean,
): string => {
  if (isEmpty(queryParams)) {
    return url;
  }

  /* eslint-disable @typescript-eslint/no-unsafe-argument */
  const paramsString = Object.entries(omitBy(queryParams, isNil))
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value
          .filter((v) => !isNil(v))
          .map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`)
          .join('&');
      } else {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      }
    })
    .join('&');
  /* eslint-enable @typescript-eslint/no-unsafe-argument */

  const char = useHash ? '#' : '?';
  return `${url}${url.includes(char) ? '&' : char}${paramsString}`;
};

export const getUrlObject = (
  pathname: string,
  query: UrlObject['query'],
): UrlObject => ({
  // trim query params from pathname
  pathname: pathname.replace(/[?#].*/, ''),
  query,
});

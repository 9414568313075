import type { FC } from 'react';

export interface OpenGraphTagsProps {
  type?: string;
  title: string;
  description?: string;
  image: string;
  publishedAt?: string;
  twitterCardType?: string;
  twitterHandle?: string;
}

const OpenGraphTags: FC<OpenGraphTagsProps> = ({
  type = 'website',
  title,
  description,
  image,
  publishedAt,
  twitterCardType = 'summary_large_image',
  twitterHandle = '@joinLeland',
}) => {
  return (
    <>
      <meta property="og:type" content={type} key="ogtype" />
      <meta property="og:title" content={title} key="ogtitle" />
      {description ? (
        <>
          <meta property="og:description" content={description} key="ogdesc" />
          <meta name="description" content={description} key="desc" />
        </>
      ) : null}
      <meta property="og:image" content={image} key="ogimage" />

      {publishedAt ? (
        <meta
          property="article:published_time"
          content={publishedAt}
          key="published_time"
        />
      ) : null}

      <meta name="twitter:card" content={twitterCardType} key="twcard" />
      <meta name="twitter:creator" content={twitterHandle} key="twhandle" />
      <meta name="twitter:title" content={title} key="twtitle" />
      <meta name="twitter:description" content={description} key="twdesc" />
      <meta name="twitter:image" content={image} key="twimage" />
    </>
  );
};

export default OpenGraphTags;

import { DAY, getFromFsCache } from '@leland-dev/leland-ui-library';

import { initializeApollo } from '../../lib/apolloClient';

import {
  NavCategoriesCacheDataDocument,
  type NavCategoriesCacheDataQuery,
  type NavCategoriesCacheDataQueryVariables,
} from './__generated-gql-types__/nav-categories-cache.generated';

export const navCategoriesDataFetcher = async () => {
  const apolloClient = initializeApollo();
  const { data } = await apolloClient.query<
    NavCategoriesCacheDataQuery,
    NavCategoriesCacheDataQueryVariables
  >({
    query: NavCategoriesCacheDataDocument,
  });
  return data;
};

export const getNavCategoriesFromFsCache = async () => {
  const data = await getFromFsCache({
    id: 'nav-categories',
    dataFetcher: navCategoriesDataFetcher,
    ttl: DAY,
  });
  return data.navCategories;
};

import {
  type GoalName,
  type Scalars,
} from '../__generated-gql-types__/globalTypes';

import {
  APPLICANT_BASE_URL,
  LIBRARY_ARTICLE_BASE_URL,
  LIBRARY_BASE_URL,
} from './constants';
import { getUrlString } from './url';

export interface GenerateSearchPathArgs {
  goal: Possible<GoalName>;
  categorySlug: Possible<string>;
  subCategorySlug: Possible<string>;
  page?: Possible<string>;
}

export const getLibraryArticleUrl = (
  slug: string,
  { absolute }: { absolute?: boolean } = {},
): string =>
  `${absolute ? APPLICANT_BASE_URL : ''}${LIBRARY_ARTICLE_BASE_URL}/${slug}`;

export const getLibraryPageUrl = ({
  slug,
  tag,
  page,
  absolute,
}: {
  slug?: Possible<string>;
  tag?: Possible<string>;
  page?: number;
  absolute?: boolean;
}): string => {
  const urlFragments = [LIBRARY_BASE_URL];
  if (absolute) urlFragments.unshift(APPLICANT_BASE_URL);

  if (slug) urlFragments.push(slug);
  // can't currently have a tag w/o a slug
  if (slug && tag) urlFragments.push(tag);
  if (page && page > 1) urlFragments.push(page.toString());
  return urlFragments.join('/');
};

export const getCoachProfileUrl = (
  slug: string,
  categorySlug?: string,
  { absolute }: { absolute?: boolean } = {},
): string => {
  const urlFragments = ['/coach', slug];
  if (absolute) urlFragments.unshift(APPLICANT_BASE_URL);

  if (categorySlug) urlFragments.push(categorySlug);
  return getUrlString(urlFragments.join('/'), {});
};

export const EVENTS_PAGE_URL = '/events';

export const generateSearchPath = ({
  goal,
  categorySlug,
  subCategorySlug,
  page,
}: GenerateSearchPathArgs): string => {
  const urlFragments = ['search'];
  if (goal) {
    urlFragments.push(goal.toLowerCase());
    if (categorySlug) {
      urlFragments.push(categorySlug);
      if (subCategorySlug) urlFragments.push(subCategorySlug);
    }
    if (page && parseInt(page, 10) > 1) urlFragments.push(page);
  }
  return `/${urlFragments.join('/')}`;
};

export const getPackageUrl = ({
  coachSlug,
  packageSlug,
}: {
  coachSlug: string;
  packageSlug: string;
}): string => {
  return getUrlString(
    `${APPLICANT_BASE_URL}/coach/${coachSlug}/p/${packageSlug}`,
    {},
  );
};

export const getClassUrl = (
  classSlug: Scalars['ClassUrn']['output'],
  isFreeEvent?: boolean,
) => {
  return isFreeEvent ? `/event/${classSlug}` : `/class/${classSlug}`;
};

import { isDateAfter } from '@leland-dev/leland-ui-library';
import {
  type ComponentPropsWithoutRef,
  type FC,
  type PropsWithChildren,
  useMemo,
} from 'react';

import {
  getNavCategoriesFromFsCache,
  navCategoriesDataFetcher,
} from '../../utils/fs-cache/nav-categories-cache';
import { getLibraryArticleUrl } from '../../utils/routing';
import AnnouncementBanner from '../AnnouncementBanner';
import { LulNavbar } from '../LulNavbar';
import MainFooter from '../MainFooter';

import PageHead, { type PageHeadProps } from './PageHead';
import {
  type LayoutNavCategoryListingFragment,
  useLayoutHeaderDataQuery,
} from './__generated-gql-types__/Layout.generated';

type Props = PropsWithChildren<
  PageHeadProps & ComponentPropsWithoutRef<typeof LulNavbar>
>;

export type LayoutDataProps = ComponentPropsWithoutRef<
  typeof LulNavbar
>['pageContentData'];

export const getStaticLayoutQuery = async (): Promise<LayoutDataProps> => {
  let navCategoryListings: LayoutNavCategoryListingFragment[];
  try {
    navCategoryListings = await getNavCategoriesFromFsCache();
  } catch {
    navCategoryListings = (await navCategoriesDataFetcher()).navCategories;
  }
  return { navCategoryListings };
};

const ANNOUNCING_SERIES_A_ARTICLE_SLUG =
  'announcing-our-12m-series-a-led-by-forerunner-ventures';
const ANNOUNCING_SERIES_A_START_DATE = new Date(
  '2024-11-13T15:00:00.000Z',
).getTime();
const ANNOUNCING_SERIES_A_END_DATE = new Date(
  '2024-12-01T15:00:00.000Z',
).getTime();

export const useLayoutDataQuery = (): LayoutDataProps => {
  const { data } = useLayoutHeaderDataQuery();

  return useMemo(
    () => ({
      navCategoryListings: data?.navCategories ?? [],
    }),
    [data],
  );
};

const Layout: FC<Props> = ({ children, pageContentData, ...pageHeadProps }) => {
  const clientPageContentData = useLayoutDataQuery();

  const pageContentDataToUse = clientPageContentData?.navCategoryListings.length
    ? clientPageContentData
    : pageContentData;
  return (
    <>
      <PageHead {...pageHeadProps} />

      {/* BODY */}
      <div className="flex flex-col-reverse">
        <MainFooter />
        <main className="min-h-screen">{children}</main>
        <LulNavbar pageContentData={pageContentDataToUse} />
        {isDateAfter(Date.now(), ANNOUNCING_SERIES_A_START_DATE) ? (
          <AnnouncementBanner
            href={getLibraryArticleUrl(ANNOUNCING_SERIES_A_ARTICLE_SLUG)}
            text={<>Announcing Leland&rsquo;s $12M Series&nbsp;A</>}
            until={ANNOUNCING_SERIES_A_END_DATE}
            dismissable
          />
        ) : null}
      </div>
    </>
  );
};

export default Layout;

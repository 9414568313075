import Head from 'next/head';
import type { FC } from 'react';

import { __STAGING__, APPLICANT_BASE_URL } from '../../utils/constants';
import OpenGraphTags, { type OpenGraphTagsProps } from '../OpenGraphTags';

export type PageHeadProps = Omit<OpenGraphTagsProps, 'image'> & {
  description: string;
  image?: string;
  canonicalLink?: string;
  noIndex?: boolean;
  prev?: string;
  next?: string;
};

const normalizeRelativeLink = (url: string) =>
  /https?:\/\//.test(url) ? url : `${APPLICANT_BASE_URL}${url}`;

const PageHead: FC<PageHeadProps> = ({
  title: titleProp,
  image = '/images/leland-og.png',
  canonicalLink,
  noIndex,
  prev,
  next,
  ...ogProps
}) => {
  const title = `${titleProp} | Leland`;

  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title key="title">{title}</title>
      {canonicalLink ? (
        <link
          rel="canonical"
          href={normalizeRelativeLink(canonicalLink)}
          key="canonical"
        />
      ) : null}
      {noIndex || __STAGING__ ? <meta name="robots" content="noindex" /> : null}
      {prev ? <link rel="prev" href={normalizeRelativeLink(prev)} /> : null}
      {next ? <link rel="next" href={normalizeRelativeLink(next)} /> : null}
      <OpenGraphTags title={title} image={image} {...ogProps} />
    </Head>
  );
};

export default PageHead;

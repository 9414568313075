// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NavCategoriesCacheNavCategoryFragment = {
  __typename?: 'NavCategoryListing';
  name: string;
  goal?: Types.GoalName | null;
  categories: Array<{
    __typename?: 'Category';
    id: string;
    name: string;
    isFeatured: boolean;
    slug: string;
    goal: Types.GoalName;
    shouldShowSubCategories?: boolean | null;
    department: { __typename?: 'Department'; id: string; name: string };
    subCategoryList?: Array<{
      __typename?: 'SubCategory';
      id: string;
      name: string;
      slug: string;
    }> | null;
  }>;
};

export type NavCategoriesCacheDataQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type NavCategoriesCacheDataQuery = {
  __typename?: 'Query';
  navCategories: Array<{
    __typename?: 'NavCategoryListing';
    name: string;
    goal?: Types.GoalName | null;
    categories: Array<{
      __typename?: 'Category';
      id: string;
      name: string;
      isFeatured: boolean;
      slug: string;
      goal: Types.GoalName;
      shouldShowSubCategories?: boolean | null;
      department: { __typename?: 'Department'; id: string; name: string };
      subCategoryList?: Array<{
        __typename?: 'SubCategory';
        id: string;
        name: string;
        slug: string;
      }> | null;
    }>;
  }>;
};

export const NavCategoriesCacheNavCategoryFragmentDoc = gql`
  fragment NavCategoriesCache_NavCategory on NavCategoryListing {
    name
    goal
    categories {
      id
      name
      isFeatured
      slug
      goal
      department {
        id
        name
      }
      shouldShowSubCategories
      subCategoryList {
        id
        name
        slug
      }
    }
  }
`;
export const NavCategoriesCacheDataDocument = gql`
  query NavCategoriesCache_Data {
    navCategories {
      ...NavCategoriesCache_NavCategory
    }
  }
  ${NavCategoriesCacheNavCategoryFragmentDoc}
`;

/**
 * __useNavCategoriesCacheDataQuery__
 *
 * To run a query within a React component, call `useNavCategoriesCacheDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavCategoriesCacheDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavCategoriesCacheDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useNavCategoriesCacheDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NavCategoriesCacheDataQuery,
    NavCategoriesCacheDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NavCategoriesCacheDataQuery,
    NavCategoriesCacheDataQueryVariables
  >(NavCategoriesCacheDataDocument, options);
}
export function useNavCategoriesCacheDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NavCategoriesCacheDataQuery,
    NavCategoriesCacheDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NavCategoriesCacheDataQuery,
    NavCategoriesCacheDataQueryVariables
  >(NavCategoriesCacheDataDocument, options);
}
export function useNavCategoriesCacheDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    NavCategoriesCacheDataQuery,
    NavCategoriesCacheDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    NavCategoriesCacheDataQuery,
    NavCategoriesCacheDataQueryVariables
  >(NavCategoriesCacheDataDocument, options);
}
export type NavCategoriesCacheDataQueryHookResult = ReturnType<
  typeof useNavCategoriesCacheDataQuery
>;
export type NavCategoriesCacheDataLazyQueryHookResult = ReturnType<
  typeof useNavCategoriesCacheDataLazyQuery
>;
export type NavCategoriesCacheDataSuspenseQueryHookResult = ReturnType<
  typeof useNavCategoriesCacheDataSuspenseQuery
>;
export type NavCategoriesCacheDataQueryResult = Apollo.QueryResult<
  NavCategoriesCacheDataQuery,
  NavCategoriesCacheDataQueryVariables
>;
